<template>
<CContainer class="login-container d-flex flex-column content-center min-vw-100 min-vh-100 mx-0">
    <div class="brand-img-container text-center d-block" style="margin-top:5%">
        <img class="brand-img" :src="logo" alt="brand"/>
    </div>
    <CRow  v-if="showButton" >
        <CCol class="p-3">
            <CCardGroup>
                <CCard class="login-form-container text-center">
                    <CCardBody>
                        <CForm>
                            <CRow>
                                <CCol col="12" class="text-center">
                                    <CButton v-if="showButton" @click="saveToLocal()" type="submit" color="danger" class="px-4 text-white">Unduh Dokumen</CButton>
                                </CCol>
                            </CRow>
                        </CForm>
                    </CCardBody>
                </CCard>
            </CCardGroup>
        </CCol>
    </CRow>
</CContainer>
</template>

<script>
const logo = require('@/assets/img/logo.png')

export default {
    name: "Download Page",
    data() {
        return {
            logo,
            showButton: false,
            filename: '',
            blobData: null
        }
    }, 
    watch: {},
    computed: {
        downloadData() {
			return this.$route.query
		},
    },
    mounted(){
        this.getZipData()
    },
    methods: {
        getZipData() {
            if( this.downloadData.doc_id != null &&  this.downloadData.token != null) {
                this.$store.dispatch(
                    {
                        type: 'document/getZipDocument',
                        docId: this.downloadData.doc_id,
                        token: this.downloadData.token
                    }
                ).then((res) => {
                    if (res) {
                        this.filename = res.filename
                        this.blobData = res.file
                        this.showButton = true
                    } else {
                        this.showButton = false
                    }
                })
            }
        },
        saveToLocal() {
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            var url = window.URL.createObjectURL(this.blobData);
            a.href = url;
            a.download = this.filename;
            a.click();
            window.URL.revokeObjectURL(url);
        }
    }
}
</script>

<style scoped>
.container{
    padding: 20px;
}
.brand-img{
    width: 150px;
    height: 150px;
    margin-bottom: 20px;
}
.login-form-container{
    width: 400px!important;
}
</style>